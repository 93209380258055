import React from 'react';
import Layout from '../../components/layout';
import TechnologyView from '../../views/technologyView'

const TechnologyPage = () => (
	<Layout>
		<TechnologyView />
	</Layout>
)

export default TechnologyPage;