import React from 'react';

import Container from '@material-ui/core/Container';

import { makeStyles } from "@material-ui/core/styles"
import { useStaticQuery, graphql } from "gatsby"

import Hero from "../../components/hero"
import TechnologyInfo from "./TechnologyInfo"

const useStyles = makeStyles(theme => ({

}))

const TechnologyViewPage = () => {
	const classes = useStyles()

	const { datoCmsPageTechnology } = useStaticQuery(graphql`
		query QueryViewTechnology {
		  datoCmsPageTechnology {
				heroTitle
				heroImage {
					gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
				}
				technologies {
					id
					title
					image {
						gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
					}
					description
					box {
						id
						title
						description
					}
				}
			}
		}
	`)

	const { heroTitle, heroImage, technologies = [] } = datoCmsPageTechnology || {}

	return (
		<React.Fragment>
			<Hero full image={heroImage} title={heroTitle}/>
			<Container maxWidth="lg">
				{technologies.map(({ id, title, image, description, box }) => (
					<TechnologyInfo key={id} title={title} image={image} description={description} box={box} />
				))}
			</Container>
		</React.Fragment>
	)
}

export default TechnologyViewPage;