import React from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from "@material-ui/core/styles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Title from "../../components/title"

const useStyles = makeStyles(theme => ({
	technologyWrapper: {
		flexDirection: 'column',
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(6),

		'& h2': {
			[theme.breakpoints.down('sm')]: {
				fontSize: '2.5rem',
			}
		},
	},
	imageContainer: {
		position: 'relative',
		height: '15vh',
		overflow: 'hidden',
		marginBottom: theme.spacing(3),
		[theme.breakpoints.up('sm')]: {
			height: '20vh'
		},
		[theme.breakpoints.up('md')]: {
			height: '30vh'
		},
		[theme.breakpoints.up('ld')]: {
			height: '40vh'
		},
	},
	title: {
		marginBottom: theme.spacing(3)
	},
	paper: {
		height: "100%",
		padding: theme.spacing(3),
		textAlign: 'center',
	},
	boxesContainer: {
		marginTop: theme.spacing(3),
	},
	boxesTitle: {
		fontSize: '1.5rem',
		fontWeight: "bold",
		marginBottom: theme.spacing(1),
	},
	description: {
		marginTop: theme.spacing(1),
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
		[theme.breakpoints.down('sm')]: {
			paddingLeft: "initial",
			paddingRight: "initial",
		},
	}
}))


const TechnologyInfo = ({ title, image, description, box }) => {
	const classes = useStyles()

	return (
		<Grid container className={classes.technologyWrapper}>
			<Grid item>
				<Title className={classes.title}>{title}</Title>
			</Grid>
			<Grid item>
				<Box className={classes.imageContainer}>
					<GatsbyImage
						alt={title}
						image={getImage(image)}
						className={classes.image}
					/>
				</Box>
			</Grid>
			<Grid item>
				<Typography className={classes.description}>
					{description}
				</Typography>
			</Grid>
			<Grid container spacing={2} className={classes.boxesContainer}>
				{box.map(({ id, title, description }) => (
					<Grid sx={12} sm={6} lg={3} item key={id}>
						<Paper elevation={0} className={classes.paper}>
							<Typography component="h4" className={classes.boxesTitle}>
								{title}
							</Typography>
							<Typography>
								{description}
							</Typography>
						</Paper>
					</Grid>
				))}
			</Grid>
		</Grid>
	)
}

export default TechnologyInfo
